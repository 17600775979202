.FortniteBarCard {
  height: 100% !important;
  max-height: 500px;
}

.FortniteBarCard__card {
  border-color: #27293d !important;
  height: 100% !important;
}

.FortniteBarCard__card-body {
  height: 100%;
}

.FortniteBarCard__text-div {
  height: 20%;
  display: flex;
  justify-content: space-between;
}

.FortniteBarCard__primary-text-left {
  color: white;
  font-size: 0.75rem;
  text-align: left;
}

.FortniteBarCard__secondary-text-left {
  color: grey;
  font-size: 0.75rem;
  text-align: left;
}

.FortniteBarCard__bold {
  color: #ff8d72;
}

.FortniteBarCard__bar-div {
  height: 80%;
  padding-bottom: 2rem;
}
