.NavBar {
  background-color: #27293d;
  height: 8vh;
  display: flex;
  justify-content: flex-start;
}

.NavBar__logo {
  margin-left: 10px;
  margin-right: 10px;
}

.NavBar__links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.NavBar__list-item {
  display: inline;
}

.NavBar__link {
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
