.TimeCard {
  border-color: #27293d !important;
  height: 100% !important;
}

.TimeCard__body {
  height: 100%;
}

.TimeCard__text-holder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; /* Horizontal center alignment */
}
