.FitnessCard {
  border-color: #27293d !important;
  height: 100% !important;
}

.FitnessCard__card-body {
  height: 100% !important;
}

.FitnessCard__primary-text-left {
  color: white;
}

.FitnessCard__secondary-text-left {
  color: grey;
  font-size: 0.75rem;
}

.FitnessCard__miles-walked-text {
  color: #1d8cf8;
  text-align: center;
}

.FitnessCard__secondary-text-center {
  color: grey;
  font-size: 0.75rem;
  text-align: center;
}

.FitnessCard__top-text {
  display: flex;
  justify-content: space-between;
}
