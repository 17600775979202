.Tools {
  height: 84%;
  padding-bottom: 3.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
}

.Tools__title-div {
  display: flex;
  justify-content: center;
}

.Tools__text {
  color: white;
}

.Tools__text-highlighted {
  color: #1d8cf8;
  text-align: center;
}

.Tools__toolcards-div {
  display: flex;
  flex-direction: column;
}

.Tools__row,
.Tools__col {
  height: 100%;
}

.Tools__row-row {
  height: 70%;
}

.Tools__card-col {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media only screen and (min-width: 1150px) {
  .Tools__toolcards-div {
    flex-direction: row;
    gap: 1rem;
  }
}
