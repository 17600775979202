.BooksReadCard {
  border-color: #27293d !important;
  height: 100% !important;
}

.BooksReadCard__card-body {
  height: 100% !important;
}

.BooksReadCard__card-text-left {
  color: white;
}

.BooksReadCard__card-books-read {
  color: #e14eca;
  text-align: center;
}

.BooksReadCard__card-text-left-secondary {
  color: grey;
  text-align: left;
  font-size: 0.75rem;
}

.BooksReadCard__card-text-center-secondary {
  color: grey;
  text-align: center;
  font-size: 0.75rem;
}

.BooksReadCard__top-text {
  display: flex;
  justify-content: space-between;
}
