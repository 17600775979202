.Donut {
  border-color: #27293d !important;
  height: 100% !important;
  max-height: 500px;
  min-width: 100px !important;
}

.Donut__card-body {
  height: 100%;
}

.Donut__card-text-div {
  height: 10%;
}

.Donut__chart-div {
  height: 90%;
  padding-bottom: 2rem;
}

.Donut__primary-text-left {
  color: white;
  font-size: 0.75rem;
  text-align: left;
}
