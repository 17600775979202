#root {
  /* min-height: 100vh;
  width: 100vw; */
  position: relative;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
p {
  color: white;
}

.App {
  background-color: #1e1e2f;
  height: 100%;
  width: 100%;
  color: #fff;
}

.row {
  width: 100%;
}

.card {
  background-color: #27293d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.card-body {
  background-color: #27293d;
  border: 0;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 10%);
}

/* .App__main-row {
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: #1e1e2f;
} */

.Index__master-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App_flexbox-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: stretch;
  padding-bottom: 3.5rem;
  padding-top: 1rem;
}

.App__center-column {
  width: 100%;
  height: 100%;
}

.App__center-content-div {
  height: 100%;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center; /* Horizontal center alignment */
}
