.About {
  max-width: 100%;
  height: 84%;
  padding-bottom: 3.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

.About__container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.About__container-header-div {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About__container-title-div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; /* Horizontal center alignment */
}

.About__container-text-color {
  color: white;
}

.About__bold {
  color: #ff8d72;
}

.About__hourglass-gif {
  max-width: 100px;
}

.About__container-title-text {
  color: #1d8cf8;
}
