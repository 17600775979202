.LoadingStats-div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingStats {
  width: 100%;
  height: 100%;
}

.LoadingStats__return-button {
  margin-top: 3%;
  width: 40%;
  background-color: #e14eca;
  background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
  background-size: 210% 210%;
  background-position: 100% 0;
  background-color: #e14eca;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #fff;
}

.LoadingStats_button-div {
  display: flex;
  justify-content: center;
}
