.FortnitePage,
.FortnitePage__master-div {
  width: 100%;
  height: 100%;
}

.FortnitePage__master-div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FortnitePage__textone-transition-appear,
.FortnitePage__days-transition-appear,
.FortnitePage__hours-transition-appear,
.FortnitePage__minutes-transition-appear,
.FortnitePage__texttwo-transition-appear,
.FortnitePage__charts-transition-appear {
  opacity: 0;
}
.FortnitePage__textone-transition-appear.FortnitePage__textone-transition-appear-active {
  opacity: 1;
  transition: all 1s step-end;
}
.FortnitePage__days-transition-appear.FortnitePage__days-transition-appear-active {
  opacity: 1;
  transition: all 2s step-end;
}
.FortnitePage__hours-transition-appear.FortnitePage__hours-transition-appear-active {
  opacity: 1;
  transition: all 3s step-end;
}
.FortnitePage__minutes-transition-appear.FortnitePage__minutes-transition-appear-active {
  opacity: 1;
  transition: all 4s step-end;
}
.FortnitePage__texttwo-transition-appear.FortnitePage__texttwo-transition-appear-active {
  opacity: 1;
  transition: all 5s step-end;
}
.FortnitePage__charts-transition-appear.FortnitePage__charts-transition-appear-active {
  opacity: 1;
  transition: all 6s step-end;
}

.FortnitePage__text-one,
.FortnitePage__text-two {
  height: 100%;
  text-align: center;
}

.FortnitePage__text-one {
  margin-top: 1rem;
}

.FortnitePage__time-row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FortnitePage__charts-row {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

.FortnitePage__info-row {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

@media only screen and (min-width: 1100px) {
  .FortnitePage__time-row {
    flex-direction: row;
  }

  .FortnitePage__charts-row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  .FortnitePage__info-row {
    flex-direction: row;
  }
}

@media only screen and (min-width: 900px) {
  .FortnitePage__time-row {
    flex-direction: row;
  }

  .FortnitePage__info-row {
    flex-direction: row;
  }
}
