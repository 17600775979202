.Footer {
  /* height: 8vh; */
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.Footer__div {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: clamp(0.5rem, 2vw, 2rem);
}

.Footer__link {
  margin-left: 10px;
}

.Footer__copyright-div {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.Footer__text {
  color: white;
  font-size: clamp(0.4rem, 2vw, 1rem);
  margin-right: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
}
