.TopGameCard {
  height: 100% !important;
  max-height: 500px;
}

.TopGameCard__card {
  border-color: #27293d !important;
  height: 100% !important;
}

.TopGameCard__card-body {
  height: 100%;
}

.TopGameCard__text-div {
  height: 20%;
  display: flex;
  justify-content: space-between;
}

.TopGameCard__primary-text-left {
  color: white;
  font-size: 0.75rem;
  text-align: left;
}

.TopGameCard__secondary-text-left {
  color: grey;
  font-size: 0.75rem;
  text-align: left;
}

.TopGameCard__bold {
  color: #ff8d72;
}

.TopGameCard__bar-div {
  height: 80%;
  padding-bottom: 2rem;
}
