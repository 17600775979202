.ChartsPage,
.ChartsPage__master-div {
  width: 100%;
  height: 100%;
}

.ChartsPage__master-div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ChartsPage__textone-transition-appear,
.ChartsPage__days-transition-appear,
.ChartsPage__hours-transition-appear,
.ChartsPage__minutes-transition-appear,
.ChartsPage__texttwo-transition-appear,
.ChartsPage__charts-transition-appear {
  opacity: 0;
}
.ChartsPage__textone-transition-appear.ChartsPage__textone-transition-appear-active {
  opacity: 1;
  transition: all 1s step-end;
}
.ChartsPage__days-transition-appear.ChartsPage__days-transition-appear-active {
  opacity: 1;
  transition: all 2s step-end;
}
.ChartsPage__hours-transition-appear.ChartsPage__hours-transition-appear-active {
  opacity: 1;
  transition: all 3s step-end;
}
.ChartsPage__minutes-transition-appear.ChartsPage__minutes-transition-appear-active {
  opacity: 1;
  transition: all 4s step-end;
}
.ChartsPage__texttwo-transition-appear.ChartsPage__texttwo-transition-appear-active {
  opacity: 1;
  transition: all 5s step-end;
}
.ChartsPage__charts-transition-appear.ChartsPage__charts-transition-appear-active {
  opacity: 1;
  transition: all 6s step-end;
}

.ChartsPage__text-one,
.ChartsPage__text-two {
  height: 100%;
  text-align: center;
}

.ChartsPage__text-one {
  margin-top: 1rem;
}

.ChartsPage__time-row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ChartsPage__charts-row {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

.ChartsPage__info-row {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

@media only screen and (min-width: 1100px) {
  .ChartsPage__time-row {
    flex-direction: row;
  }

  .ChartsPage__charts-row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  .ChartsPage__info-row {
    flex-direction: row;
  }
}

@media only screen and (min-width: 900px) {
  .ChartsPage__time-row {
    flex-direction: row;
  }

  .ChartsPage__info-row {
    flex-direction: row;
  }
}
