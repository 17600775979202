.SteamCard {
  background-color: #27293d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.SteamCard__account-inputs {
  color: white;
}

.SteamCard__button-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SteamCard__btn-primary {
  margin-top: 3%;
  width: 40%;
  background-color: #e14eca;
  background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
  background-size: 210% 210%;
  background-position: 100% 0;
  background-color: #e14eca;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #fff;
}

.SteamCard__main-text {
  text-align: center;
  color: #1d8cf8;
}

@media only screen and (min-width: 1200px) {
  .SteamCard {
    width: 50%;
  }
}
