.PageNotFound {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3.5rem;
  /* height: 84%; */
  /* min-height: 96vh; */
}

.PageNotFound__img {
  max-width: 500px;
  width: 100%;
}
.PageNotFound__h1 {
  color: white;
  /* margin-right: 100px; */
}
